import {
  Box,
  Flex,
  //  Img,
  Text,
  Image,
} from '@chakra-ui/react';
import React from 'react';
import BannerImage1 from '../../../../assets/images/landing/Rectangle 5829 2.png';
import BannerImage2 from '../../../../assets/images/landing/Rectangle 5852.png';
const Banner = () => {
  console.log('banner');
  return (
    <Flex
      justifyContent="center"
      alignItems={['center', 'center', 'flex-start']} // Center content on small screens, flex-start on medium and large screens
      paddingTop={{ base: '40px', xl: '95px' }} // Responsive padding top
      paddingX={{ base: '20px', md: '50px', lg: '70px', xl: '100px' }} // Responsive padding X
      direction={{ base: 'column', xl: 'row' }} // Flex direction for small, medium, large screens
      id="home"
    >
      <Flex
        justifyContent={['center', 'center', 'flex-start']}
        width={{ base: '100%', xl: '50%' }}
      >
        {/* Updated text styles */}
        <Box
        paddingTop={{xl:"100px"}}
          paddingRight={{ base: '0px', xl: '20px' }}
          paddingLeft={{ base: '0px', xl: '56px' }}
        >
          <Text
            width={['100%', '100%', '380px']}
            color="#333"
            fontSize={{ base: '40px', md: '80px', xl: '120px' }}
            fontStyle="normal"
            fontWeight="600"
            lineHeight={['100px', '120px', '152px']}
            letterSpacing={['8px', '10px', '12px']}
            textAlign="start"
          >
            AKRU
          </Text>
          <Text
            paddingTop="6px"
            width={['100%', '100%', '479px']}
            color="#333"
            fontSize={['18px', '20px', '26px']}
            fontStyle="normal"
            fontWeight="500"
            lineHeight={['30px', '35px', '45px']}
          >
            Revolutionizing Real-World Assets through Digital Securitization
          </Text>
          <Text
            paddingTop="30px"
            width={{ base: 'fit-content', xl: '400px' }}
            color="#333"
            fontSize={{ base: '18', md: '20', lg: '24' }}
            fontStyle="normal"
            fontWeight="300"
            lineHeight={['normal', 'normal', 'normal']}
          >
            --------------------{' '}
            <Text as="span" fontWeight="600">
              AKRU –{' '}
            </Text>
            stands as the epitome of innovation and inclusivity, seamlessly
            navigating the intricate landscape of finance.
          </Text>
        </Box>
      </Flex>
      <Box
        paddingTop={{ base: '60px', md: '90px', xl: '100px' }}
        paddingBottom={{ base: '180px', xl: '100px' }}
        // paddingY={{ base: '120px', xl: '100px' }}
        width={{ base: '100%', xl: '50%' }}
        position="relative"
      >
        <Image
          src={BannerImage1}
          backgroundPosition="right"
          backgroundSize="auto"
          backgroundRepeat="no-repeat"
          height="630px"
          w="full"
          borderRadius="10px"
          marginTop={{ base: '', lg: '-60px' }}
          backgroundAttachment="fixed"
          paddingLeft={{ base: '0px', xl: '30px' }}
          zIndex="0"
        />
        <Image
          src={BannerImage2}
          position="absolute"
          left={{ base: '3px',md:"150px",lg:"0px" ,xl: '-100px' }}
          top={{base:"450px",md:"390px",xl:"250px"}}
          width="330px" // Adjust the width of the second image as needed
          height="auto"
          // transition="transform 0.3s ease-in-out"
          // opacity={isHovered ? 0.7 : 1}
          // transform={`scale(${isHovered ? 1.1 : 1})`}
        />
      </Box>
    </Flex>
  );
};
export default Banner;
