import { Box, Container, Flex, Image, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import BannerImage1 from '../../../../assets/images/landing/N.png';
import { useNavigate } from 'react-router-dom';
const SeeOurProducts = () => {
  const navigation = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Container maxW={{base:"92%",xl:"1050px"}} p="0px" id="about">
      <Flex
paddingTop={{base:"0px",xl:"80px"}}
        alignItems={{base:"center",xl:"end"}}
        justifyContent="space-between"
        flexDirection={{ base: 'column', xl: 'row' }}
      >
        {/* <VStack
          order={{ base: 2, xl: 1 }}
          width={{ base: '100%', xl: '35%' }}
          pos="relative"
          mb={{ base: '20px', xl: '0px' }}
        >
          <Image
        _hover={{
          width: '80%',  // Adjust the zoom-out width as needed
          opacity: 0.5,
          transform: 'translateY(-10px)',  // Adjust the translateY value for the parallax effect
          transition: 'width 0.3s ease, opacity 0.3s ease, transform 0.3s ease',
        }}
            src={BannerImage1}
            margin={{ base: '30', mb: '30' }}
            width={{ base: '80%', md: '50%', lg: '100%' }}
          />

        </VStack> */}
        <Box
          paddingTop={{ base: '50px', xl: '0px' }}
          position="relative"
          width={{ base: 'fit-content', xl: '412px' }}
          height={{ base: 'inherit', xl: '482px' }}
          overflow="hidden"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Image
            src={BannerImage1}
            transition="transform 0.3s ease-in-out"
            transform={`scale(${isHovered ? 1.1 : 1})`}
          />
          {isHovered && (
            <Box
              width={{ base: '85%', xl: '90%' }}
              height={{ base: '85%', xl: '90%' }}
              pos="absolute"
              top={{ base: '55%', sm: '53%', xl: '50%' }}
              left="50%"
              transform="translate(-50%,-50%)"
              borderRadius="5px"
            ></Box>
          )}
        </Box>

        <Flex
          paddingX={{base:"0px",xl:"100px"}}
          order={{ base: 1, xl: 2 }}
          flexDirection={{ base: 'column', xl: 'row' }}
          height="inherit"
          justifyContent="space-between"
          w={{ base: '100%', xl: '60%' }}
          paddingBottom={{ base: '', xl: '50px' }}
        >
          <VStack
            paddingLeft={{ base: '0px', xl: '0px' }}
            gap="15px"
            justifyContent="space-evenly"
            alignItems="start"
          >
            <Text
              textAlign={{
                base: 'left',
                md: 'left',
                lg: 'start',
                xl: 'start',
              }}
              paddingTop={{ base: '40px', xl: '0px' }}
              width={{ base: '100%', xl: '486px' }}
              color="#666"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="30px"
            >
              AKRU fuels a revolutionary paradigm, reshaping finance through
              innovation and inclusivity. Our mission transcends traditional
              boundaries, aiming to democratize investment opportunities and
              reshape the spheres of on-chain finance, commercial real estate
              investing and real estate asset management in emerging markets.
              Utilizing cutting-edge technologies such as AI, NLP, and
              blockchain, AKRU is at the forefront of transforming financial
              landscapes, ensuring a future where empowerment in finance is
              limitless.
            </Text>
            <Text
              textAlign="start"
              cursor="pointer"
              color="#333"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="20px"
              width="65px"
              paddingTop="25px"
              _hover={{
                color: '#052768',
                '& svg': {
                  transform: 'translateX(15px)',
                  transition: 'transform 0.5s ease',
                },
              }}
              onClick={() => {
                navigation('/learnMore');
                window.scrollTo(0, 0);
              }}
            >
              LEARN MORE
              <Text paddingTop="5px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="6"
                  viewBox="0 0 16 6"
                  _hover={{ fill: '#052768' }}
                >
                  <path
                    d="M1 2.5C0.723858 2.5 0.5 2.72386 0.5 3C0.5 3.27614 0.723858 3.5 1 3.5V2.5ZM16 3L11 0.113249V5.88675L16 3ZM1 3.5H11.5V2.5H1V3.5Z"
                    fill="#333333"
                  />
                </svg>
              </Text>
            </Text>
          </VStack>
        </Flex>
      </Flex>
    </Container>
  );
};
export default SeeOurProducts;
