import { Box } from '@chakra-ui/react';
import React from 'react';
// import ElevatingAsset from './components/evelatingAsset';
// import AiPoweredProducts from './components/aiPoweredProducts';
// import OwnerPortal from './components/ownerPortal';
// import TenantPortal from './components/tenantPortal';
// import ProviderPortal from './components/providerPortal';
import DocumentAnalysis from '../realestateData/components/documentAnalysis';
import RealEstateFeature from '../realestateData/components/realestateFeature';

const AiPowered = () => {
  return (
    <Box>
      <DocumentAnalysis />
      <RealEstateFeature />
    </Box>
  );
};

export default AiPowered;
