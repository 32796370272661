import { Box, Container, Flex, Img, Text } from '@chakra-ui/react';
import React from 'react';
import CrossChainFeatureImage1 from '../../../../../../assets/images/landing/Group.png';
import CrossChainFeatureImage2 from '../../../../../../assets/images/landing/Group (1).png';
import CrossChainFeatureImage3 from '../../../../../../assets/images/landing/Group (2).png';
import CrossChainFeatureImage4 from '../../../../../../assets/images/landing/Group 12.37.46 PM.png';
import CrossChainFeatureImage5 from '../../../../../../assets/images/landing/Group (3).png';
import CrossChainFeatureImage6 from '../../../../../../assets/images/landing/Group (4).png';
const CrossChainFeature = () => {
  return (
    <Container maxW={{base:"92%",xl:"1050px"}} p="0px">
      <Box>
        <Box paddingY="120px">
          <Text
            color="#333"
            textAlign="center"
            fontSize="64px"
            fontStyle=" normal"
            fontWeight="600"
            lineHeight="80px"
          >
            FEATURES
          </Text>
        </Box>
        <Flex justifyContent="space-between" flexWrap={{ base: '', md: '', lg: 'wrap' }} width="100%" flexDirection={{ base: 'column', md: 'column', lg: 'row' }}>
          {/* card-1 */}
          <Box
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
          >
            <Box>
              <Img src={CrossChainFeatureImage1} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Cross-Chain Transactions
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                A-On Fi Network breaks down silos, connecting major financial
                institutions for seamless cross-chain transactions.
              </Text>
            </Box>
          </Box>
          {/* card-2 */}
          <Box
            marginTop={{ base: '30', md: '30', lg: '0' }}
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
          >
            <Box>
              <Img src={CrossChainFeatureImage2} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Interoperability Across Blockchains
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                A-On Fi Network ensures interoperability by connecting and
                facilitating transactions across different blockchain networks.
              </Text>
            </Box>
          </Box>
          {/* card-3 */}
          <Box
            marginTop="30px"
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
          >
            <Box>
              <Img src={CrossChainFeatureImage3} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                On-Chain Identity Verification
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                Seamlessly integrate on-chain identity verification to enhance
                security and trust in your financial transactions.
              </Text>
            </Box>
          </Box>
          {/* card-4 */}
          <Box
            marginTop="30px"
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
          >
            <Box>
              <Img src={CrossChainFeatureImage4} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Private Subnet Node Infrastructure
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                AKRU's unique private subnet node-based infrastructure enables
                and enhances cross-chain transactions, ensuring privacy and
                security.
              </Text>
            </Box>
          </Box>
          {/* card-5*/}
          <Box
            marginTop="30px"
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
          >
            <Box>
              <Img src={CrossChainFeatureImage5} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Near Instant T+0 Settlement
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                Experience the future of rapid settlement with near instant T+0
                speed, reshaping the way transactions unfold and accelerating
                financial operations.
              </Text>
            </Box>
          </Box>
          {/* card-6 */}
          <Box
            marginTop="30px"
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
          >
            <Box>
              <Img src={CrossChainFeatureImage6} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Smart Contracts Revolution
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                Explore the transformative capabilities of smart contracts,
                unlocking unparalleled efficiency through streamlined and
                automated processes.
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default CrossChainFeature;
