import React from 'react'

const ArrowRight = ({width , height , fill}) => {
  return (
    <div>
    <svg width="16" height="6" viewBox="0 0 16 6" fill="black" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 2.5C0.723858 2.5 0.5 2.72386 0.5 3C0.5 3.27614 0.723858 3.5 1 3.5V2.5ZM16 3L11 0.113249V5.88675L16 3ZM1 3.5H11.5V2.5H1V3.5Z" fill="#333333"/>
    </svg>
    </div>
  )
}

export default ArrowRight;