import {
  Box,
  Container,
  Flex,
  Text,
  Collapse,
  // useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import AkruAnalyzerImage1 from '../../../../assets/images/landing/union1.png';
const AkruAnalyzer = () => {
  const [activeDoc1, setActiveDoc1] = useState(null);
  const [activeDoc2, setActiveDoc2] = useState(null);
  // const { isOpen, onToggle } = useDisclosure();
  const handleNavigation = () => {
    window.open('https://akruonfi.com/document-analyzer', '_blank');
  };
  const handleNavigation2 = () => {
    window.open('https://akruonfi.com/akru-admin', '_blank');
  };
  return (
    <>
      <Box
        backgroundImage={AkruAnalyzerImage1}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
      >
        <Container maxW={{ base: '92%', xl: '1050px' }} padding="0px">
          <Flex
            paddingY="120px"
            gap="6"
            direction={{ base: 'column', lg: 'row' }}
          >
            <Box
              // width="48%"
              width={{ base: '98%', lg: '48%' }}
              className="background-image-1"
              cursor="pointer"
              position="relative"
              overflow="hidden"
              onClick={() => {
                handleNavigation();
                window.scrollTo(0, 0);
              }}
              onMouseEnter={() => setActiveDoc1(true)}
              onMouseLeave={() => setActiveDoc1(false)}
            >
              <Box
                backgroundImage={`url('../../assets/images/landing/Rectangle 5844 (2).png')`}
                backgroundPosition="center"
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                height="550px"
                borderRadius="10px"
                position="relative"
                zIndex="1"
              >
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  borderRadius="10px"
                  background={`rgba(0, 0, 0, ${activeDoc1 ? 0.4 : 0.2})`}
                  transition="background 0.3s ease-in-out"
                  zIndex="2"
                >
                  <Flex height="fill-available" alignItems="end">
                    <Box paddingX="40px" paddingY="20px">
                      <Text
                        color="#FFF"
                        fontSize=" 30px"
                        fontStyle=" normal"
                        fontWeight=" 600"
                        lineHeight="normal"
                      >
                        AKRU Document Analyzer
                      </Text>
                      <Collapse
                        in={activeDoc1}
                        transition={{
                          exit: { delay: 0.5 },
                          enter: { duration: 0.5 },
                        }}
                      >
                        <Text
                          paddingTop="20px"
                          color="#FFF"
                          fontSize=" 16px"
                          fontStyle=" normal"
                          fontWeight=" 500"
                          lineHeight="30px"
                          display={activeDoc1 ? '' : 'none'}
                        >
                          Our AI-NLP-LLM Document Analyzer offers a
                          comprehensive overview of your investments, converting
                          key documents into actionable data. Engage directly
                          for specific insights, streamlining investment
                          oversight.
                        </Text>

                        <Box
                          display={activeDoc1 ? '' : 'none'}
                          cursor="pointer"
                          paddingTop="50px"
                        >
                          <Text
                            width="80px"
                            color="#FFF"
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight="600"
                            lineHeight="20px"
                            textTransform="uppercase"
                          >
                            Learn More
                            <Box paddingTop="5px">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="8"
                                viewBox="0 0 21 8"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.3871 0L21 3.81796L14.3871 7.63592V4.47925H1.16129C0.79607 4.47925 0.5 4.18318 0.5 3.81796C0.5 3.45274 0.79607 3.15667 1.16129 3.15667H14.3871V0Z"
                                  fill="white"
                                />
                              </svg>
                            </Box>
                          </Text>
                        </Box>
                      </Collapse>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
            <Box
              // width="48%"
              width={{ base: '98%', lg: '48%' }}
              className="background-image-2"
              cursor="pointer"
              position="relative"
              overflow="hidden"
              onClick={() => {
                handleNavigation2();
                window.scrollTo(0, 0);
              }}
              onMouseEnter={() => setActiveDoc2(true)}
              onMouseLeave={() => setActiveDoc2(false)}
            >
              <Box
                backgroundImage={`url('../../assets/images/landing/Rectangle 5844 (2).png')`}
                backgroundPosition="center"
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                height="550px"
                borderRadius="10px"
                position="relative"
                zIndex="1"
              >
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  borderRadius="10px"
                  background={`rgba(0, 0, 0, ${activeDoc2 ? 0.4 : 0.2})`}
                  transition="background 0.3s ease-in-out"
                  zIndex="2"
                >
                  <Flex height="fill-available" alignItems="end">
                    <Box paddingX="40px" paddingY="20px">
                      <Text
                        color="#FFF"
                        fontSize=" 30px"
                        fontStyle=" normal"
                        fontWeight=" 600"
                        lineHeight="normal"
                      >
                        AKRU Admin
                      </Text>
                      <Collapse
                        in={activeDoc2}
                        transition={{
                          exit: { delay: 0.5 },
                          enter: { duration: 0.5 },
                        }}
                      >
                        <Text
                          paddingTop="20px"
                          color="#FFF"
                          fontSize=" 16px"
                          fontStyle=" normal"
                          fontWeight=" 500"
                          lineHeight="30px"
                          display={activeDoc2 ? '' : 'none'}
                        >
                          AKRU Admin is a comprehensive fund administration
                          platform that leverages AI-NLP-LLM and Blockchain
                          technology to streamline capital raising, capital
                          management and fund administration.
                        </Text>
                        <Box
                          display={activeDoc2 ? '' : 'none'}
                          cursor="pointer"
                          paddingTop="50px"
                        >
                          <Text
                            width="80px"
                            color="#FFF"
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight="600"
                            lineHeight="20px"
                            textTransform="uppercase"
                          >
                            Learn More
                            <Box paddingTop="5px">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="8"
                                viewBox="0 0 21 8"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.3871 0L21 3.81796L14.3871 7.63592V4.47925H1.16129C0.79607 4.47925 0.5 4.18318 0.5 3.81796C0.5 3.45274 0.79607 3.15667 1.16129 3.15667H14.3871V0Z"
                                  fill="white"
                                />
                              </svg>
                            </Box>
                          </Text>
                        </Box>
                      </Collapse>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Box>
    </>
  );
};

export default AkruAnalyzer;
