import React from 'react';
import ReactDOM from 'react-dom';
import {
  ChakraProvider,
  ColorModeScript,
  extendTheme,
  CSSReset,
} from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const theme = extendTheme({
  fonts: {
    body: 'Poppins',
    heading: 'Poppins',
  },
});

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <CSSReset />
      <ColorModeScript />
      <App />
    </ChakraProvider>
  </BrowserRouter>
);

serviceWorker.unregister();
reportWebVitals();
