import {
  Box,
  ChakraProvider,
  createStandaloneToast,
  CSSReset,
} from '@chakra-ui/react';
import React from 'react';
import './assets/style/style.css';
import RoutesPage from './routes';

export const { ToastContainer, toast } = createStandaloneToast();

const App = () => {
  return (
    <ChakraProvider>
      <CSSReset />
      <Box>
        <RoutesPage />
      </Box>
      {/* Include the ToastContainer */}
      <ToastContainer />
    </ChakraProvider>
  );
};

export default App;
