import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  FormControl,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { showToast } from '../../../../components/showToast';
import ArrowRight from '../../../../icons/arrow';

const LetsTalk = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    mobilephone: '',
    company: '',
    area_of_interest: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const requiredFields = [
    'firstname',
    'lastname',
    'email',
    'mobilephone',
    'company',
    'area_of_interest',
  ];

  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailRegex.test(formData.email)) {
      errors.email = 'Enter a valid email address';
    }

    requiredFields.forEach(fieldName => {
      const formattedFieldName = fieldName.replace('_', ' '); // Replace underscores with spaces
      if (!formData[fieldName].trim()) {
        errors[fieldName] = `${
          formattedFieldName.charAt(0).toUpperCase() +
          formattedFieldName.slice(1)
        } is required`;
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };
  const handleFormChanged = (name, value) => {
    setFormErrors(prevErrors => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // useEffect(() => {
  //   console.log('form data', formData);
  // }, [formData]);
  const handleSubmitForm = async e => {
    e.preventDefault();
    console.log('trying');
    const isValid = validateForm();
    if (isValid) {
      try {
        // Format the data using the mapping
        const formattedData = {
          fields: Object.keys(formData).map(fieldName => ({
            objectTypeId: '0-1',
            name: fieldName,
            value: formData[fieldName],
          })),
        };
        const response = await axios.post(
          'https://api.hsforms.com/submissions/v3/integration/submit/20164312/be7cd6c0-0c4c-4428-8aac-9ca7eccae5db',
          JSON.stringify(formattedData),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response) {
          setFormData({
            firstname: '',
            lastname: '',
            email: '',
            mobilephone: '',
            company: '',
            area_of_interest: '',
          });

          console.log(response);
          showToast(
            'success',
            'Thank you for your interest! Our team will be in contact with you shortly.'
          );
        }
        // console.log('response', response.data);
      } catch (error) {
        console.error('error', error);
        showToast('error', error);
      }
    } else {
      showToast('error', 'Please fill in all required fields.');
    }
  };

  return (
    <Box paddingY="40px" backgroundColor="">
      <Box className={isXLScreen ? 'container' : 'container-responsive'}>
        <form>
          <HStack
            paddingTop="40px"
            gap="20px"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <FormControl
              width={{ base: '100%', xl: '48%' }}
              isRequired={true}
              isInvalid={formErrors.firstname}
            >
              <Input
                type="text"
                onChange={e => handleFormChanged('firstname', e.target.value)}
                value={formData.firstname}
                color="#666"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                height="52px"
                backgroundColor="white"
                border="1px solid #ccc"
                placeholder="First Name"
              />
              <Text color="red.500" fontSize="12px">
                {formErrors.firstname}
              </Text>
            </FormControl>
            <FormControl
              width={{ base: '100%', xl: '48%' }}
              isRequired={true}
              isInvalid={formErrors.lastname}
            >
              <Input
                value={formData.lastname}
                color="#666"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                height="52px"
                backgroundColor="white"
                border="1px solid #ccc"
                onChange={e => handleFormChanged('lastname', e.target.value)}
                type="text"
                placeholder="Last Name"
              />
              <Text color="red.500" fontSize="12px">
                {formErrors.lastname}
              </Text>
            </FormControl>
            <FormControl
              width={{ base: '100%', xl: '48%' }}
              isRequired={true}
              isInvalid={formErrors.email}
            >
              <Input
                onChange={e => handleFormChanged('email', e.target.value)}
                type="email"
                value={formData.email}
                color="#666"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                height="52px"
                backgroundColor="white"
                border="1px solid #ccc"
                placeholder="Email"
              />
              <Text color="red.500" fontSize="12px">
                {formErrors.email}
              </Text>
            </FormControl>
            <FormControl
              width={{ base: '100%', xl: '48%' }}
              isRequired={true}
              isInvalid={formErrors.mobilephone}
            >
              <Input
                value={formData.mobilephone}
                type="number"
                color="#666"
                onChange={e => handleFormChanged('mobilephone', e.target.value)}
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                height="52px"
                backgroundColor="white"
                border="1px solid #ccc"
                placeholder="Phone Number"
              />
              <Text color="red.500" fontSize="12px">
                {formErrors.mobilephone}
              </Text>
            </FormControl>
            <FormControl
              width={{ base: '100%', xl: '48%' }}
              isRequired={true}
              isInvalid={formErrors.company}
            >
              <Input
                value={formData.company}
                type="text"
                color="#666"
                fontSize="14px"
                fontStyle="normal"
                onChange={e => handleFormChanged('company', e.target.value)}
                fontWeight="500"
                lineHeight="normal"
                height="52px"
                backgroundColor="white"
                border="1px solid #ccc"
                placeholder="Company Name"
              />
              <Text color="red.500" fontSize="12px">
                {formErrors.company}
              </Text>
            </FormControl>
          </HStack>
          <Box>
            <Text
              paddingY="30px"
              color="#333"
              fontSize="20px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="normal"
            >
              Areas of Interest{' '}
            </Text>
            <RadioGroup
              onChange={e => handleFormChanged('area_of_interest', e)}
            >
              <HStack flexWrap="wrap" gap="30px">
                <Radio
                  size="lg"
                  colorScheme="blue"
                  borderColor="#ccc"
                  borderRadius="2px"
                  value="Cross-Chain Transaction Enablement"
                  backgroundColor="#FFF"
                  border="1px solid #CCC"
                >
                  <Text
                    color=" #666"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="normal"
                  >
                    Cross-Chain Transaction Enablement
                  </Text>
                </Radio>
                <Radio
                  size="lg"
                  colorScheme="blue"
                  borderColor="#ccc"
                  borderRadius="2px"
                  value="Fractionalization"
                  backgroundColor="#FFF"
                  border="1px solid #CCC"
                >
                  <Text
                    color=" #666"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="normal"
                  >
                    Fractionalization
                  </Text>
                </Radio>
                <Radio
                  size="lg"
                  colorScheme="blue"
                  borderColor="#ccc"
                  borderRadius="2px"
                  value="On-Chain Finance"
                  backgroundColor="#FFF"
                  border="1px solid #CCC"
                >
                  <Text
                    color=" #666"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="normal"
                  >
                    On-Chain Finance
                  </Text>
                </Radio>
                <Radio
                  size="lg"
                  colorScheme="blue"
                  borderColor="#ccc"
                  borderRadius="2px"
                  value="Ai-Powered Document Analysis"
                  backgroundColor="#FFF"
                  border="1px solid #CCC"
                >
                  <Text
                    color=" #666"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="normal"
                  >
                    Ai-Powered Document Analysis
                  </Text>
                </Radio>
                <Radio
                  size="lg"
                  colorScheme="blue"
                  borderColor="#ccc"
                  borderRadius="2px"
                  value="Real Asset Management For Emerging Markets"
                  backgroundColor="#FFF"
                  border="1px solid #CCC"
                >
                  <Text
                    color=" #666"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="normal"
                  >
                    Real Asset Management For Emerging Markets
                  </Text>
                </Radio>
              </HStack>
              {formErrors.area_of_interest && (
                <Text color="red">{formErrors.area_of_interest}</Text>
              )}
            </RadioGroup>
            {/* <Box paddingTop="50px">
              <Button
                width="inherit"
                fontSize="16px"
                color="black"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="20px"
                backgroundColor="transparent"
                // _hover={{ backgroundColor: '#FD9D5D', color: '#FFF' }}
                colorScheme="white"
                padding="0px"
                display="flex"
                flexDirection="column"
                alignItems="baseline"
                gap="20px"
                onClick={handleSubmitForm}
              >
                Submit
                <ArrowRight />
              </Button>
            </Box> */}
            <Box>
              <Box paddingTop="40px" display="flex" justifyContent="start">
                <Text
                  width="inherit"
                  borderRadius="5px"
                  fontSize="16px"
                  color="black"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  paddingY="2px"
                  _hover={{
                    color: '#052768',
                    '& svg': {
                      transform: 'translateX(15px)',
                      transition: 'transform 0.5s ease',
                    },
                  }}
                  colorScheme="white"
                  onClick={handleSubmitForm}
                  display="flex"
                  flexDirection="column"
                  gap="10px"
                  cursor="pointer"
                >
                  Submit
                  <ArrowRight />
                </Text>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
export default LetsTalk;
