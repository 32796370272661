import {
  Box,
  Container,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import FutureOfFinance from '../futureOfFinance';
import OurProducts from '../ourProducts';
import AkruIm from '../akruIm';
import AkruAnalyzer from '../akruAnalyzer';
import AkruServicing from '../akruServicing';
import CrossChainData from '../crossChainData';
import Fractionalization from '../fractionalization';
import RealEstateData from '../realestateData';
import AiPowered from '../aiPowered';
import Contact from '../Contact';
import PrivateMarkete from '../privateMarkete';
// import DemoContact from '../requestDemo/demoForm';

const TabsData = () => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = index => {
    setActiveTab(index);
  };
  return (
    <Box
      paddingTop={{base:"20px",xl:"90px"}}
      paddingBottom={{ base: '0px', lg: '100px' }}
      // paddingBottom="120px"
    >
      <Flex
        alignItems="center"
        width="100%"
        direction={{ base: 'column', lg: 'row' }}
      >
        <Tabs variant="" width="100%">
          <Container maxW={{ base: '92%', xl: '1050px' }} p="0">
            <TabList
              border="1px dashed #333"
              borderRadius="5px"
              colorScheme="teal"
              display="flex"
              flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
              justifyContent={{ base: 'center', md: 'center', lg: '' }}
            >
              <Tab
                borderRadius="5px"
                bgGradient={
                  activeTab === 0 ? 'linear(to-b, #052768, #18428F)' : ''
                }
                onClick={() => handleTabClick(0)}
                py="20px"
                borderRight={{ base: 'none', lg: '1px dashed #333' }}
                borderBottom={{ base: '1px dashed #333', lg: 'none' }}
              >
                <Flex alignItems="center" justifyContent="center">
                  <Box cursor="pointer">
                    <Text
                      width="158px"
                      textAlign="center"
                      color={activeTab === 0 ? '#FFF' : '#333'}
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="600"
                      lineHeight="20px"
                      textTransform="uppercase"
                    >
                      ON-CHAIN FINANCE
                    </Text>
                  </Box>
                </Flex>
              </Tab>
              <Tab
                bgGradient={
                  activeTab === 1 ? 'linear(to-b, #052768, #18428F)' : ''
                }
                onClick={() => handleTabClick(1)}
                py="20px"
                borderRight={{ base: 'none', lg: '1px dashed #333' }}
                borderBottom={{ base: '1px dashed #333', lg: 'none' }}
              >
                <Flex alignItems="center" justifyContent="center">
                  <Box cursor="pointer">
                    <Text
                      textAlign="center"
                      color={activeTab === 1 ? '#FFF' : '#333'}
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="600"
                      lineHeight="20px"
                      textTransform="uppercase"
                    >
                      Cross-Chain Transaction Enablement
                    </Text>
                  </Box>
                </Flex>
              </Tab>
              <Tab
                bgGradient={
                  activeTab === 2 ? 'linear(to-b, #052768, #18428F)' : ''
                }
                onClick={() => handleTabClick(2)}
                py="20px"
                borderRight={{ base: 'none', lg: '1px dashed #333' }}
                borderBottom={{ base: '1px dashed #333', lg: 'none' }}
              >
                <Flex alignItems="center" justifyContent="center">
                  <Box cursor="pointer">
                    <Text
                      textAlign="center"
                      color={activeTab === 2 ? '#FFF' : '#333'}
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="600"
                      lineHeight="20px"
                      textTransform="uppercase"
                    >
                      fractionalization
                    </Text>
                  </Box>
                </Flex>
              </Tab>
              <Tab
                bgGradient={
                  activeTab === 3 ? 'linear(to-b, #052768, #18428F)' : ''
                }
                onClick={() => handleTabClick(3)}
                py="20px"
                borderRight={{ base: 'none', lg: '1px dashed #333' }}
                borderBottom={{ base: '1px dashed #333', lg: 'none' }}
              >
                <Flex alignItems="center" justifyContent="center">
                  <Box cursor="pointer">
                    <Text
                      textAlign="center"
                      color={activeTab === 3 ? '#FFF' : '#333'}
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="600"
                      lineHeight="20px"
                      textTransform="uppercase"
                    >
                      REAL ESTATE ASSET MANAGEMENT <br />
                      <Text as="span" fontSize="10px">
                        for emerging markets
                      </Text>
                    </Text>
                  </Box>
                </Flex>
              </Tab>
              <Tab
                bgGradient={
                  activeTab === 4 ? 'linear(to-b, #052768, #18428F)' : ''
                }
                onClick={() => handleTabClick(4)}
                py="20px"
                borderBottom={{ base: '', xl: 'none' }}
              >
                <Flex alignItems="center" justifyContent="center">
                  <Box cursor="pointer">
                    <Text
                      textAlign="center"
                      color={activeTab === 4 ? '#FFF' : '#333'}
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="600"
                      lineHeight="20px"
                      textTransform="uppercase"
                    >
                      AI-Powered Document Analysis
                    </Text>
                  </Box>
                </Flex>
              </Tab>
            </TabList>
          </Container>
          <TabPanels id="plateform">
            <TabPanel width="100%" p="0px">
              <FutureOfFinance />
              <OurProducts />
              <AkruIm />
              <AkruAnalyzer />
              <AkruServicing />
              <PrivateMarkete />
              <Contact />
            </TabPanel>
            <TabPanel width="100%" p="0">
              <CrossChainData />
            </TabPanel>
            <TabPanel width="100%">
              <Fractionalization />
            </TabPanel>
            <TabPanel p="0px" width="100%">
              <RealEstateData />
            </TabPanel>
            <TabPanel width="100%" p="0">
              <AiPowered />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Box>
  );
};
export default TabsData;
