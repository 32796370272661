import { Box, Container, Flex, Img, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import OurProductImage from '../../../../assets/images/landing/Rectangle 5834 2.png';
import OurProductImage1 from '../../../../assets/images/landing/Union.png';

const OurProducts = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleNavigation = () => {
    window.open('https://akruonfi.com/a-onfi-network', '_blank');
  };
  return (
    <Box
      backgroundImage={OurProductImage1}
      backgroundSize="100% 100%"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      marginTop="100px"
    >
      <Container maxW={{ base: '92%', xl: '1050px' }} padding="0px">
        <Box>
          <Box
            width={{ base: '96%', xl: '100%' }}
            //  paddingTop="120px"
            paddingTop={{ base: '120px', lg: '120px' }}
            borderTopRadius="500px"
          >
            <Text
              textAlign="center"
              color="#333"
              // fontSize=" 64px"
              fontSize={{ base: '50px', lg: '64px' }}
              fontStyle="normal"
              fontWeight=" 600"
              lineHeight={{ base: '60px', xl: '80px' }}
            >
              OUR
              <br />
              PRODUCTS
            </Text>
          </Box>
          <Box
            // paddingTop="120px"
            paddingTop={{ base: '50px', lg: '120px' }}
            paddingBottom="100px"
          >
            <Flex alignItems="center">
              <Box
                width={{ base: '100%', xl: '50%' }}
                alignContent={{ base: 'center', xl: '' }}
              >
                <Text
                  width="fit-content"
                  color="#333"
                  fontSize="32px"
                  fontStyle="normal"
                  fontWeight=" 600"
                  lineHeight="normal"
                >
                  A On-Fi Network
                </Text>
                <Text
                  paddingTop={{ base: '40px', xl: '20px' }}
                  width={{ base: '100%', xl: '388px' }}
                  color="#333"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="30px"
                >
                  AKRU's A On-Fi Network streamlines cross-chain transactions,
                  integrating on-chain identity verification and smart contracts
                  within major financial institutions, setting a new standard
                  for private market efficiency.
                </Text>
                <Box>
                  <Box
                    paddingTop="40px"
                    left="-30"
                    display={{ base: 'block', xl: 'none' }}
                  >
                    <Img width="fill-available" src={OurProductImage} />
                  </Box>
                </Box>
                <Box paddingTop={{ base: '0px', xl: '50px' }}>
                  <Box
                    cursor="pointer"
                    borderRadius="10px"
                    bgGradient="linear(to-r, #18428F, #052768)"
                    _hover={{
                      bgGradient: 'linear(to-r, #052768, #18428F)',
                      '& svg': {
                        transform: 'translateX(15px)',
                        transition: 'transform 0.3s ease',
                      },
                    }}
                    justifyContent="start"
                    paddingX="42px"
                    paddingY="26"
                    onClick={() => {
                      handleNavigation();

                      window.scrollTo(0, 0);
                    }}
                  >
                    <Text
                      width="77px"
                      color="#FFF"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="600"
                      lineHeight="20px"
                      textTransform="uppercase"
                    >
                      learn More
                      <Box paddingTop="5px">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="8"
                          viewBox="0 0 21 8"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.3871 0L21 3.81796L14.3871 7.63592V4.47925H1.16129C0.79607 4.47925 0.5 4.18318 0.5 3.81796C0.5 3.45274 0.79607 3.15667 1.16129 3.15667H14.3871V0Z"
                            fill="white"
                          />
                        </svg>
                      </Box>
                    </Text>
                  </Box>
                </Box>
              </Box>

              <Box
                display={{ base: 'none', xl: 'block' }}
                paddingTop={{ base: '50px', xl: '0px' }}
                left="-30"
                position="relative"
                overflow="hidden"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Img
                  width="fill-available"
                  src={OurProductImage}
                  transition="transform 0.3s ease-in-out"
                  // opacity={isHovered ? 0.7 : 1}
                  transform={`scale(${isHovered ? 1.1 : 1})`}
                />
                {isHovered && (
                  <Box
                    width={{ base: '85%', xl: '90%' }}
                    height={{ base: '85%', xl: '90%' }}
                    // border="2px solid #FD8534"
                    pos="absolute"
                    top={{ base: '55%', sm: '53%', xl: '50%' }}
                    left="50%"
                    transform="translate(-50%,-50%)"
                    borderRadius="5px"
                  ></Box>
                )}
              </Box>
            </Flex>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default OurProducts;
