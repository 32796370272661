import { Box } from '@chakra-ui/react';
import React from 'react';
import Header from '../../components/header';
import Banner from './components/banner';
import SeeOurProducts from './components/seeOurProducts';
import Footer from '../../components/footer';
import TabsData from './components/tabsData';
console.log("usememeo")
const Home = () => {
  return (
    <Box>
      <Header />
            <Banner />
            <SeeOurProducts />
            <TabsData />
            <Footer />
    </Box>
  );
};

export default Home;
