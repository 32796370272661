import {
  Box,
  Container,
  // Flex,
  HStack,
  Image,
  // Img,
  ListItem,
  // OrderedList,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import FutureImage1 from '../../../../assets/images/landing/Subtract 3.png';
const Experience = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Container maxW="1050px" p="0px">
      <Box position="relative">
        <Box
          paddingTop={{ base: '50px', xl: '0px' }}
          position="relative"
          // width={{ base: "fit-content", xl: "412px" }}
          // height={{ base: "inherit", xl: "482px" }}
          overflow="hidden"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Image
            src={FutureImage1}
            transition="transform 0.3s ease-in-out"
            // opacity={isHovered ? 0.7 : 1}
            transform={`scale(${isHovered ? 1.1 : 1})`}
          />
          {isHovered && (
            <Box
              // width={{ base: "85%", xl: "90%" }}
              // height={{ base: "85%", xl: "90%" }}
              // border="2px solid #FD8534"
              pos="absolute"
              top={{ base: '55%', sm: '53%', xl: '50%' }}
              left="50%"
              transform="translate(-50%,-50%)"
              borderRadius="5px"
            ></Box>
          )}
        </Box>

        {/* <Img
          src={FutureImage1}
          // marginX={{base:'10px' , lg:''}}
        /> */}
        <Box
          paddingRight={{ base: '0', md: '0', lg: '60px' }}
          pos="absolute"
          top={{ base: '175', md: '220', lg: '380' }}
        >
          <Text
            width={{ base: '177px', md: '520px', lg: '520px' }}
            color="#333"
            fontSize={{ base: '18px', md: '40px', lg: '48px' }}
            fontStyle="normal"
            fontWeight="600"
            lineHeight={{ base: '32px', md: '50px', lg: '60px' }}
            // marginX={{ base: '10px', lg: '' }}
          >
            EMBRANCE THE FUTURE OF FINANCE WITH AKRU
          </Text>
        </Box>
      </Box>
      <HStack
        gap="50px"
        marginTop="100px"
        alignItems={{ base: 'center', md: 'center', lg: 'start' }}
        justifyContent="space-between"
        display={{ base: 'flex', md: 'flex', lg: 'flex' }}
        flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
      >
        <VStack
          // gap="70px"
          alignItems="start"
          justifyContent="space-evenly"
          w="100%"
          height="320px"
        >
          <Text
            paddingTop={{ base: '350px', lg: '80px' }}
            width={{ base: '250', md: '300', lg: '574' }}
            color="#333"
            fontSize={{ base: '18', md: '20', lg: '24' }}
            fontStyle="normal"
            fontWeight="300"
            lineHeight="normal"
            marginX={{ base: '20px', lg: '' }}
          >
            -------------------- At AKRU we are reshaping the world of finance.
            Our unique suite of services is designed to open new doors in the
            realms of on-chain finance, real estate investment, and real estate
            asset management in emerging markets.
          </Text>
          {/* <HStack justifyContent="space-between" gap="10px"
            alignItems={{ base: 'center', md: 'center', lg: 'start' }}
            display={{ base: 'flex', md: 'flex', lg: 'flex' }}
            flexDirection={{ base: 'column', md: 'row' }}>
              <Text
                width="250px"
                color="#666"
                fontSize="16px"
                fontStyle="normal"
                fontWeight=" 700"
                lineHeight="30px"
                marginX={{base:'20px' , lg:''}}
  
              >
               What Our Demo Offers
              </Text>
             
            </HStack> */}
          <Box>
            <Text
              width={{ base: '100%', md: '520px', lg: '520px' }}
              color="#333"
              fontSize={{ base: '18px', md: '40px', lg: '24px' }}
              fontStyle="normal"
              fontWeight="600"
              lineHeight={{ base: '32px', md: '50px', lg: '80px' }}
              marginX={{ base: '10px', lg: '20px' }}
              // marginTop={{ base: '10px', lg: '40px' }}
              margin={{ base: '10px 20px 0px', lg: '40px 0px 0px 0px' }}
            >
              Our Cutting-Edge Services
            </Text>
            {/* <UnorderedList style={{ listStyleType: 'none', padding: 0 }}>
      <ListItem marginBottom="10px">
         <Text as="span" fontWeight="500">Customized Experience</Text> A walkthrough of services most relevant to your business.
      </ListItem>
      <ListItem marginBottom="10px">
        <Text as="span" fontWeight="500">Interactive Session</Text> Real-time showcases of our platform’s capabilities..
      </ListItem>
      <ListItem marginBottom="10px">
        <Text as="span" fontWeight="500">Expert Insights:</Text> Our team will answer your questions and discuss how AKRU can fit into your business model..
      </ListItem>
    </UnorderedList> */}

            <UnorderedList margin={{ base: '0px 39px', lg: '10px 0px' }}>
              <ListItem marginTop={{ base: '10px', lg: '15px' }}>
                <Text as="span" fontWeight="500">
                  Cross-Chain Transaction Enablement
                </Text>{' '}
                Experience a seamless financial world without boundaries.
              </ListItem>
              <ListItem marginTop={{ base: '10px', lg: '15px' }}>
                <Text as="span" fontWeight="500">
                  Fractionalization of Real Estate
                </Text>{' '}
                Invest in premium real estate for as little as $1000.
              </ListItem>
              <ListItem marginTop={{ base: '10px', lg: '15px' }}>
                <Text as="span" fontWeight="500">
                  On-Chain Finance
                </Text>{' '}
                Step into the future of finance with blockchain technology and
                natural language processing.
              </ListItem>
              <ListItem marginTop={{ base: '10px', lg: '15px' }}>
                <Text as="span" fontWeight="500">
                  AI-Powered Document Analysis
                </Text>{' '}
                Revolutionize your document analysis with natural language
                processing and artificial intelligence.
              </ListItem>
              <ListItem marginTop={{ base: '10px', lg: '15px' }}>
                <Text as="span" fontWeight="500">
                  Real Estate Asset Management for Emerging Markets:
                </Text>{' '}
                Tailored solutions for the unique challenges of emerging
                markets.
              </ListItem>
            </UnorderedList>
          </Box>
        </VStack>
      </HStack>
    </Container>
  );
};

export default Experience;
