import { Box } from '@chakra-ui/react';
import React from 'react';
import Experience from './experience';
import Footer from '../../../../components/footer';
import Header from '../../../../components/header';
import DemoContact from './demoContact';

const LearnMore = () => {
  return (
    <Box>
      <Header/>
      <Experience/>
      <DemoContact/>
      <Footer />
    </Box>
  );
};

export default LearnMore;
