import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import ComingSoon from '../View/comingSoon';
import Home from "../View/Home";
import RequestDemo from '../View/Home/components/requestDemo';
import LearnMore from '../View/Home/components/learnMore';
import Rems from '../View/rems';


const RoutesPage = () => {
  return (
    <Routes>
       <Route path="/" element={<Home />} />
       <Route path="/requestDemo" element={<RequestDemo />} />
       <Route path="/learnMore" element={<LearnMore />} />
       <Route path="/rems" element={<Rems/>} />
      {/*<Route path="/" element={<ComingSoon />} />*/}
    </Routes>
  );
};

export default RoutesPage;
