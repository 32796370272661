import { Box, Container, Divider, Img, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import BannerImage from '../../../../assets/images/rems/logo.png';
const Banner = () => {
  return (
    <Box>
      {' '}
      <Box className="bg-rems">
        <Container maxW={{ base: '92%', xl: '1050px' }} p="0">
          <VStack height="900px" justifyContent="center" gap="80px">
            <Box>
              <Img src={BannerImage} />
            </Box>
            <Box>
              <Text
                width={{ base: 'fit-content', xl: '651px' }}
                color="#FFF"
                textAlign="center"
                fontSize={{ base: '30px', xl: ' 60px' }}
                fontStyle="normal"
                fontWeight=" 600"
                lineHeight="normal"
              >
                Revolutionizing Real Estate Management
              </Text>
            </Box>
          </VStack>
        </Container>
      </Box>
      <Container maxW={{ base: '92%', xl: '1050px' }} p="0px">
        <Text
          paddingTop={{ base: '60px', xl: '120px' }}
          textAlign="center"
          color="#333"
          fontSize={{ base: '18px', xl: ' 24px' }}
          fontStyle="normal"
          fontWeight=" 500"
          lineHeight={{ base: '30px', xl: '40px' }}
        >
          REMS revolutionizes real estate management with a unified,
          technology-driven platform. Tailored for stakeholders like developers,
          agents, and buyers & sellers, it simplifies complex processes and
          ensures transparency, especially in property transactions and
          management. By tackling industry challenges such as trust and data
          complexity, REMS enhances efficiency and fosters reliability in every
          interaction.
        </Text>
        <Divider
          border="1px solid #CCC"
          marginY={{ base: '60px', xl: '120px' }}
        />
      </Container>
    </Box>
  );
};

export default Banner;
