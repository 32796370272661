import { UseToastOptions } from '@chakra-ui/react'
import {toast} from "../../App";


export function showToast(
    status: UseToastOptions['status'],
    title?: UseToastOptions['title'],
    description?: UseToastOptions['description'],
) {
    return toast({
        status,
        title,
        description,
        position: 'top-right',
    })
}