import { Box, Container, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import AkruAnalyzerImage1 from '../../../../../../assets/images/landing/union1.png';
const TenantPortal = () => {
  const [activeDoc1, setActiveDoc1] = useState(null);
  const [activeDoc2, setActiveDoc2] = useState(null);

  return (
    <Box
      backgroundImage={AkruAnalyzerImage1}
      backgroundSize="100% 100%"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <Container maxW="1050px" padding="0px">
        <Flex
          paddingY="120px"
          gap="6"
          flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
        >
          <Box
            width={{ base: '100%', md: '48%', lg: '48%' }}
            className="background-portal-1"
            cursor="pointer"
            position="relative"
            overflow="hidden"
            onMouseEnter={() => setActiveDoc1(true)}
            onMouseLeave={() => setActiveDoc1(false)}
          >
            <Box
              backgroundImage={`url('../../assets/images/ai-powered/Rectangle 5844.png')`}
              backgroundPosition="center"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              height="550px"
              borderRadius="10px"
              position="relative"
              zIndex="1"
            >
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                borderRadius="10px"
                background={`rgba(0, 0, 0, ${activeDoc1 ? 0.4 : 0.2})`}
                transition="background 0.3s ease-in-out"
                zIndex="2"
              >
                <Flex height="fill-available" alignItems="end">
                  <Box paddingX="40px" paddingY="20px">
                    <Text
                      color="#FFF"
                      fontSize=" 30px"
                      fontStyle=" normal"
                      fontWeight=" 600"
                      lineHeight="normal"
                    >
                      Tenant Portal
                    </Text>
                    <Text
                      paddingTop="20px"
                      color="#FFF"
                      fontSize=" 16px"
                      fontStyle=" normal"
                      fontWeight=" 500"
                      lineHeight="30px"
                      display={activeDoc1 ? '' : 'none'}
                    >
                      AKRU's Tenant Portal simplifies the rental experience,
                      offering tenants a user-friendly platform to manage rent
                      payments, maintenance requests, and documents seamlessly.
                    </Text>
                    {/* <Box
                      display={activeDoc1 ? '' : 'none'}
                      cursor="pointer"
                      paddingTop="50px"
                    >
                      <Text
                        width="80px"
                        color="#FFF"
                        fontSize="16px"
                        fontStyle="normal"
                        fontWeight="600"
                        lineHeight="20px"
                        textTransform="uppercase"
                      >
                        LEARN MORE
                        <Box paddingTop="5px">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="8"
                            viewBox="0 0 21 8"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.3871 0L21 3.81796L14.3871 7.63592V4.47925H1.16129C0.79607 4.47925 0.5 4.18318 0.5 3.81796C0.5 3.45274 0.79607 3.15667 1.16129 3.15667H14.3871V0Z"
                              fill="white"
                            />
                          </svg>
                        </Box>
                      </Text>
                    </Box> */}
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Box>
          <Box
            width={{ base: '100%', md: '48%', lg: '48%' }}
            className="background-portal-2"
            cursor="pointer"
            position="relative"
            overflow="hidden"
            onMouseEnter={() => setActiveDoc2(true)}
            onMouseLeave={() => setActiveDoc2(false)}
          >
            <Box
              backgroundImage={`url('../../assets/images/ai-powered/Rectangle 5845.png')`}
              backgroundPosition="center"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              height="550px"
              borderRadius="10px"
              position="relative"
              zIndex="1"
            >
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                borderRadius="10px"
                background={`rgba(0, 0, 0, ${activeDoc2 ? 0.4 : 0.2})`}
                transition="background 0.3s ease-in-out"
                zIndex="2"
              >
                <Flex height="fill-available" alignItems="end">
                  <Box paddingX="40px" paddingY="20px">
                    <Text
                      color="#FFF"
                      fontSize=" 30px"
                      fontStyle=" normal"
                      fontWeight=" 600"
                      lineHeight="normal"
                    >
                      Property Manager Portal
                    </Text>
                    <Text
                      paddingTop="20px"
                      color="#FFF"
                      fontSize=" 16px"
                      fontStyle=" normal"
                      fontWeight=" 500"
                      lineHeight="30px"
                      display={activeDoc2 ? '' : 'none'}
                    >
                      Our Property Manager Portal optimizes workflow for
                      property managers, providing tools for tasks such as
                      submitting maintenance proposals, interacting with
                      tenants, and managing service provider tickets.
                    </Text>
                    {/* <Box
                      display={activeDoc2 ? '' : 'none'}
                      cursor="pointer"
                      paddingTop="50px"
                    >
                      <Text
                        width="80px"
                        color="#FFF"
                        fontSize="16px"
                        fontStyle="normal"
                        fontWeight="600"
                        lineHeight="20px"
                        textTransform="uppercase"
                      >
                        LEARN MORE
                        <Box paddingTop="5px">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="8"
                            viewBox="0 0 21 8"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.3871 0L21 3.81796L14.3871 7.63592V4.47925H1.16129C0.79607 4.47925 0.5 4.18318 0.5 3.81796C0.5 3.45274 0.79607 3.15667 1.16129 3.15667H14.3871V0Z"
                              fill="white"
                            />
                          </svg>
                        </Box>
                      </Text>
                    </Box> */}
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default TenantPortal;
