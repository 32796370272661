import { Box, Container, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import AiPoweredProducts2 from '../../../../assets/images/landing/Union.png';
import LetsTalk from './demoForm';
const DemoContact = () => {
  return (
    <Box
      backgroundImage={AiPoweredProducts2}
      backgroundSize="100% 100%"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      marginTop="100px"
    >
      <Container maxW="1050px" padding="0px">
        <Box>
          <Box
            width="100%"
            //  paddingTop="120px"
            paddingTop={{ base: '50px', lg: '30px' }}
            borderTopRadius="500px"
          ></Box>
          <Box
            // paddingTop="120px"
            paddingTop={{ base: '50px', lg: '120px' }}
            paddingBottom="100px"
          >
            <Flex alignItems="center">
              <Box
                width={{ base: '100%', xl: '100%' }}
                alignContent={{ base: 'center', xl: '' }}
              >
                <Box>
                  <Text
                    width="fit-content"
                    color="#333"
                    fontSize="32px"
                    fontStyle="normal"
                    fontWeight=" 600"
                    lineHeight="normal"
                    // marginLeft={{ base: '70px' }}
                  >
                    Book a Demo
                  </Text>
                  <Text
                    paddingTop="20px"
                    // width="388px"
                    width={{ base: '290px', lg: '75%' }}
                    // margin={{ base: '30px' }}
                    color="#333"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="30px"
                  >
                    Fill out the form below to schedule your demo. Our team will
                    contact you to understand your interests and set up a time
                    that works best for you.{' '}
                  </Text>
                </Box>
                <LetsTalk />
              </Box>
            </Flex>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default DemoContact;
