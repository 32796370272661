import { Box } from '@chakra-ui/react';
import React from 'react';
import Header from '../../components/header';
import Banner from './components/banner';
import KeyFeatures from './components/keyFeatures';
import Seamless from './components/seamlessAgent';
import Footer from "../../components/footer"

const Rems = () => {
  return (
    <Box>
      <Header />
      <Banner/>
      <KeyFeatures/>
      <Seamless/>
      <Footer/>
    </Box>
  );
};

export default Rems;
