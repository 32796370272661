import { Box } from '@chakra-ui/react';
import React from // , { useEffect }
'react';
import Experience from './experience';
import Footer from '../../../../components/footer';
import Header from '../../../../components/header';
import DemoContact from './demoContact';

const RequestDemo = () => {
  return (
    <Box>
      <Header />
      <Experience />
      <DemoContact />
      <Footer />
    </Box>
  );
};

export default RequestDemo;
