import { Box, Container, HStack, Img, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import KeyImage1 from '../../../../assets/images/rems/Rectangle 5825.png';
import KeyImage2 from '../../../../assets/images/rems/Rectangle 5826.png';
const KeyFeatures = () => {
  return (
    <Container maxW={{ base: '92%', xl: '1050px' }} p="0">
      <VStack gap="20px">
        <Text
          textAlign="center"
          color="#222"
          fontSize={{ base: '30px', xl: '40px' }}
          fontStyle="normal"
          fontWeight=" 600"
          lineHeight="normal"
        >
          Key Features
        </Text>
        <Text
          width={{ base: 'fit-content', xl: '589px' }}
          textAlign="center"
          color="#666"
          fontSize={{ base: '14px', xl: '18px' }}
          fontStyle="normal"
          fontWeight=" 400"
          lineHeight="30px"
        >
          Explore the wide range of functionalities designed to enhance every
          aspect of real estate management.
        </Text>
      </VStack>
      <Box paddingY={{ base: '60px', xl: '100px' }}>
        <HStack
          flexDirection={{ base: 'column', xl: 'row' }}
          alignItems="center"
          gap="20px"
        >
          <Box>
            <Img width="fill-available" src={KeyImage1} />
          </Box>
          <Box>
            <Text
              width={{ base: 'fit-content', xl: '255px' }}
              textAlign="start"
              color="#333"
              fontSize={{ base: '18px', xl: '24px' }}
              fontStyle="normal"
              fontWeight=" 500"
              lineHeight="30px"
            >
              Effortless Real Estate Management 
            </Text>
            <Text
              paddingY={{ base: '20px', xl: '30px' }}
              width={{ base: 'fit-content', xl: '519px' }}
              textAlign="start"
              color="#666"
              fontSize={{ base: '14px', xl: '18px' }}
              fontStyle="normal"
              fontWeight=" 400"
              lineHeight="30px"
            >
              REMS offers a streamlined experience for users allowing you to
              manage every aspect of real estate management with ease and
              efficiency.
            </Text>
            {/* <Box>
              <Box
                cursor="pointer"
                paddingX="30px"
                paddingY="12px"
                width="180px"
                backgroundColor="#00557F"
                borderRadius="5px"
                color="#FFF"
                // _hover={{ color: '#00557F', background: '#FFF' }}
              >
                Request a Demo
              </Box>
            </Box> */}
          </Box>
        </HStack>
        <HStack
          flexDirection={{ base: 'column', xl: 'row' }}
          paddingTop={{ base: '60px', xl: '100px' }}
          alignItems="center"
          gap="20px"
        >
          <Box>
            <Text
              width={{ base: 'fit-content', xl: '292px' }}
              textAlign="start"
              color="#333"
              fontSize={{ base: '18px', xl: '24px' }}
              fontStyle="normal"
              fontWeight=" 500"
              lineHeight="30px"
            >
              Revolutionize Your Management Approach
            </Text>
            <Text
              paddingY={{ base: '20px', xl: '30px' }}
              width={{ base: 'fit-content', xl: '519px' }}
              textAlign="start"
              color="#666"
              fontSize={{ base: '14px', xl: '18px' }}
              fontStyle="normal"
              fontWeight=" 400"
              lineHeight="30px"
            >
              REMS provides a unified platform, streamlining property management
              with features like centralized request management and efficient
              communication tools.
            </Text>
            {/* <Box>
              <Box
                cursor="pointer"
                paddingX="30px"
                paddingY="12px"
                width="180px"
                backgroundColor="#00557F"
                borderRadius="5px"
                color="#FFF"
                // _hover={{ color: '#00557F', background: '#FFF' }}
              >
                Request a Demo
              </Box>
            </Box> */}
          </Box>
          <Box>
            <Img width="fill-available" src={KeyImage2} />
          </Box>
        </HStack>
      </Box>
    </Container>
  );
};

export default KeyFeatures;
