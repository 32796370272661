import { Box } from '@chakra-ui/react';
import React from 'react';
import ElevatingAsset from '../aiPowered/components/evelatingAsset';
import AiPoweredProducts from '../aiPowered/components/aiPoweredProducts';
import OwnerPortal from '../aiPowered/components/ownerPortal';
import TenantPortal from '../aiPowered/components/tenantPortal';
import ProviderPortal from '../aiPowered/components/providerPortal';

const RealEstateData = () => {
  return (
    <Box>
      <ElevatingAsset />
      <AiPoweredProducts />
      <OwnerPortal />
      <TenantPortal />
      <ProviderPortal />
    </Box>
  );
};

export default RealEstateData;
