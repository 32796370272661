import { Box, Container, Flex, Img, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import AkruImImage from '../../../../assets/images/landing/Rectangle 5851.png';

const PrivateMarkete = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleNavigation = () => {
    window.open('https://akruonfi.com/akru-alts', '_blank');
  };

  return (
    <Container maxW={{ base: '92%', xl: '1050px' }} padding="0px">
      <Box paddingY={{ base: '50px', lg: '120px' }}>
        <Flex alignItems="center">
          {/* <Box
            position="relative"
            zIndex="1"
            left="-20px"
            display={{ base: 'none', lg: 'block' }}
            _hover={{
              img: {
                transform: 'scale(1.1)', // Adjust the scale as needed
                transition: 'transform 0.3s ease', // Adjust the duration and easing as needed
              },
            }}
          >
            <Img src={AkruImImage} />
          </Box> */}
          <Box
            display={{ base: 'none', xl: 'block' }}
            paddingTop={{ base: '50px', xl: '0px' }}
            zIndex="1"
            left="-20px"
            position="relative"
            // width={{ base: "fit-content", xl: "" }}
            // height={{ base: "inherit", xl: "482px" }}
            overflow="hidden"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Img
              src={AkruImImage}
              transition="transform 0.3s ease-in-out"
              // opacity={isHovered ? 0.7 : 1}
              transform={`scale(${isHovered ? 1.1 : 1})`}
            />
            {isHovered && (
              <Box
                width={{ base: '85%', xl: '90%' }}
                height={{ base: '85%', xl: '90%' }}
                // border="2px solid #FD8534"
                pos="absolute"
                top={{ base: '55%', sm: '53%', xl: '50%' }}
                left="50%"
                transform="translate(-50%,-50%)"
                borderRadius="5px"
              ></Box>
            )}
          </Box>

          <Box
            width={{ base: '100%', lg: '50%' }}
            // width="50%"
          >
            <Text
              width="fit-content"
              color="#333"
              fontSize="25px"
              fontStyle="normal"
              fontWeight=" 600"
              lineHeight="normal"

            >
              Alts. Custom Private Markets
            </Text>
            <Text
              paddingTop={{ base: '20px', xl: '20px' }}
              paddingBottom={{ base: '20px', xl: '0px' }}
              // width="388px"
              width={{ base: '100%', lg: '388px' }}
              margin={{ base: '0px', lg: '' }}
              color="#333"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="30px"
            >
              Elevate finance with a focus on customization, cost efficiency,
              and enhanced security. Our platform provides a new level of
              transparency in asset management, going beyond mere digitization
              for greater trust and efficiency.
            </Text>
            <Box
              zIndex="1"
              left="-20px"
              display={{ base: 'block', lg: 'none' }}
            >
              <Img src={AkruImImage} />
            </Box>

            <Box
              position="relative"
              // left="-50px"
              left={{ base: '0px', lg: '-50px' }}
              paddingTop={{ base: '0px', xl: '50px' }}
            >
              <Box
                cursor="pointer"
                borderRadius="10px"
                bgGradient="linear(to-r, #18428F, #052768)"
                _hover={{
                  bgGradient: 'linear(to-r, #052768, #18428F)',
                  '& svg': {
                    transform: 'translateX(15px)',
                    transition: 'transform 0.3s ease',
                  },
                }}
                justifyContent="start"
                paddingX="42px"
                paddingY="26"
                onClick={() => {
                  handleNavigation();
                  window.scrollTo(0, 0);
                }}
              >
                <Text
                  width="77px"
                  color="#FFF"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="20px"
                  textTransform="uppercase"
                  marginLeft="30px"
                >
                  Learn More
                  <Box paddingTop="5px">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="8"
                      viewBox="0 0 21 8"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.3871 0L21 3.81796L14.3871 7.63592V4.47925H1.16129C0.79607 4.47925 0.5 4.18318 0.5 3.81796C0.5 3.45274 0.79607 3.15667 1.16129 3.15667H14.3871V0Z"
                        fill="white"
                      />
                    </svg>
                  </Box>
                </Text>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default PrivateMarkete;
