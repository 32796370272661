import React, { useState,  } from 'react';
// import axios from 'axios';
import {
  // Box,
  // Flex,
  // FormControl,
  // HStack,
  // Input,
  // Text,
  useBreakpointValue,
  // Textarea,
  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalHeader,
  // ModalFooter,
  // ModalBody,
  // VStack,
  // ModalCloseButton,
  // Button,
  // useDisclosure
} from '@chakra-ui/react';

// import { showToast } from '../../../../components/showToast';
// import ArrowRight from '../../../../icons/arrow';

const Contact = () => {
  // const isXLScreen = useBreakpointValue({ base: false, xl: true });
 
  return (
    // <Box paddingY="10px" backgroundColor="" id="contact">
    //   <Flex alignItems="center" justifyContent="center">
    //     <Text
    //       width="fit-content"
    //       color="#333"
    //       fontSize="32px"
    //       fontStyle="normal"
    //       fontWeight=" 600"
    //       lineHeight="normal"
    //       // marginLeft={{ base: '70px' }}
    //     >
    //       Let's Talk
    //     </Text>
    //   </Flex>
    //   <Box className={isXLScreen ? 'container' : 'container-responsive'}>
    //     <form>
    //       <HStack
    //         paddingTop="40px"
    //         gap="40px"
    //         flexWrap="wrap"
    //         justifyContent="space-between"
    //       >
    //         <FormControl
    //           width={{ base: '100%', xl: '48%' }}
    //           isRequired={true}
    //           isInvalid={formErrors.firstname}
    //         >
    //           <Input
    //             type="text"
    //             onChange={e => handleFormChanged('firstname', e.target.value)}
    //             value={formData.firstname}
    //             color="#666"
    //             fontSize="14px"
    //             fontStyle="normal"
    //             fontWeight="500"
    //             lineHeight="normal"
    //             height="52px"
    //             backgroundColor="white"
    //             border="1px solid #ccc"
    //             placeholder="First Name"
    //           />
    //           <Text color="red.500" fontSize="12px">
    //             {formErrors.firstname}
    //           </Text>
    //         </FormControl>
    //         <FormControl
    //           width={{ base: '100%', xl: '48%' }}
    //           isRequired={true}
    //           isInvalid={formErrors.lastname}
    //         >
    //           <Input
    //             value={formData.lastname}
    //             color="#666"
    //             fontSize="14px"
    //             fontStyle="normal"
    //             fontWeight="500"
    //             lineHeight="normal"
    //             height="52px"
    //             backgroundColor="white"
    //             border="1px solid #ccc"
    //             onChange={e => handleFormChanged('lastname', e.target.value)}
    //             type="text"
    //             placeholder="Last Name"
    //           />
    //           <Text color="red.500" fontSize="12px">
    //             {formErrors.lastname}
    //           </Text>
    //         </FormControl>
    //         <FormControl
    //           width={{ base: '100%', xl: '48%' }}
    //           isRequired={true}
    //           isInvalid={formErrors.email}
    //         >
    //           <Input
    //             onChange={e => handleFormChanged('email', e.target.value)}
    //             type="email"
    //             value={formData.email}
    //             color="#666"
    //             fontSize="14px"
    //             fontStyle="normal"
    //             fontWeight="500"
    //             lineHeight="normal"
    //             height="52px"
    //             backgroundColor="white"
    //             border="1px solid #ccc"
    //             placeholder="Email"
    //           />
    //           <Text color="red.500" fontSize="12px">
    //             {formErrors.email}
    //           </Text>
    //         </FormControl>
    //         <FormControl
    //           width={{ base: '100%', xl: '48%' }}
    //           isRequired={true}
    //           isInvalid={formErrors.mobilephone}
    //         >
    //           <Input
    //             value={formData.mobilephone}
    //             type="number"
    //             color="#666"
    //             onChange={e => handleFormChanged('mobilephone', e.target.value)}
    //             fontSize="14px"
    //             fontStyle="normal"
    //             fontWeight="500"
    //             lineHeight="normal"
    //             height="52px"
    //             backgroundColor="white"
    //             border="1px solid #ccc"
    //             placeholder="Phone Number"
    //           />
    //           <Text color="red.500" fontSize="12px">
    //             {formErrors.mobilephone}
    //           </Text>
    //         </FormControl>
    //         <FormControl
    //           width={{ base: '100%', xl: '100%' }}
    //           isRequired={true}
    //           isInvalid={formErrors.message}
    //         >
    //           <Textarea
    //             value={formData.message}
    //             color="#666"
    //             fontSize="14px"
    //             fontStyle="normal"
    //             onChange={e => handleFormChanged('message', e.target.value)}
    //             fontWeight="500"
    //             lineHeight="normal"
    //             height="auto" // Let the height adjust automatically based on content
    //             rows={9} // Adjust the number of visible rows as needed
    //             backgroundColor="white"
    //             border="1px solid #ccc"
    //             placeholder="Type Message"
    //           />
    //           <Text color="red.500" fontSize="12px">
    //             {formErrors.message}
    //           </Text>
    //         </FormControl>
    //       </HStack>
    //       <Box>
    //         <Box paddingTop="40px" display="flex" justifyContent="flex-end">
    //           <Text
    //             width="inherit"
    //             borderRadius="5px"
    //             fontSize="16px"
    //             color="black"
    //             fontStyle="normal"
    //             fontWeight="500"
    //             lineHeight="normal"
    //             paddingX="20px"
    //             paddingY="2px"
    //             _hover={{
    //               color: '#052768',
    //               '& svg': {
    //                 transform: 'translateX(15px)',
    //                 transition: 'transform 0.5s ease',
    //               },
    //             }}
    //             colorScheme="white"
    //             onClick={handleSubmitForm}
    //             display="flex"
    //             flexDirection="column"
    //             gap="10px"
    //             cursor="pointer"
    //           >
    //             Submit
    //             <ArrowRight />
    //           </Text>
    //         </Box>
    //       </Box>
    //     </form>
    //   </Box>
    // </Box>
<></>
    
  );
};
export default Contact;
