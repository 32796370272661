import { Box } from '@chakra-ui/react';
import React from 'react';
import RealEstateInvesting from './components/realestateInvesting';
import FractionalizationFeature from './components/fractionalizationFeature';

const Fractionalization = () => {
  return (
    <Box>
      <RealEstateInvesting />
      <FractionalizationFeature />
    </Box>
  );
};

export default Fractionalization;
