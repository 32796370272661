import { Box, Container, Flex, Img, Text } from '@chakra-ui/react';
import React from 'react';
import FractionalizationFeatureImage1 from '../../../../../../assets/images/fractionalization/Group.png';
import FractionalizationFeatureImage2 from '../../../../../../assets/images/fractionalization/Group (1).png';
import FractionalizationFeatureImage3 from '../../../../../../assets/images/fractionalization/Group (2).png';
import FractionalizationFeatureImage4 from '../../../../../../assets/images/fractionalization/Group (3).png';
import FractionalizationFeatureImage5 from '../../../../../../assets/images/fractionalization/Group 3897.png';
const FractionalizationFeature = () => {
  return (
    <Container maxW={{ base: '92%', xl: '1050px' }} p="0px">
      <Box>
        <Box paddingY="120px">
          <Text
            color="#333"
            textAlign="center"
            fontSize="64px"
            fontStyle=" normal"
            fontWeight="600"
            lineHeight="80px"
          >
            FEATURES
          </Text>
        </Box>
        <Flex justifyContent="space-between" flexWrap="wrap" width="100%">
          {/* card-1 */}
          <Box
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
          >
            <Box>
              <Img src={FractionalizationFeatureImage1} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Simplified Investing
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                Investors can start investing with as little as $1000 with the
                ability to diversify their portfolio through affordable, direct
                investments in the properties of their choice.
              </Text>
            </Box>
          </Box>
          {/* card-2 */}
          <Box
            marginTop={{ base: '30', md: '30', lg: '0' }}
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
          >
            <Box>
              <Img src={FractionalizationFeatureImage2} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                One-Stop Platform
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                Our easy-to-use platform makes investing as simple-as-a-click
                and allows you to monitor the trajectory and performance of your
                investments.
              </Text>
            </Box>
          </Box>
          {/* card-3 */}
          <Box
            marginTop="30px"
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
          >
            <Box>
              <Img src={FractionalizationFeatureImage3} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Real-Time Returns
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                With your AKRU Wallet, you can receive returns on your
                investment from the comfort of your home without having to go
                through excessive paperwork or long closing delays.
              </Text>
            </Box>
          </Box>
          {/* card-4 */}
          <Box
            marginTop="30px"
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
          >
            <Box>
              <Img src={FractionalizationFeatureImage4} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Enhanced Liquidity
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                Our real-time marketplace provides you with the ability to buy,
                sell, and trade securities.
              </Text>
            </Box>
          </Box>
          {/* card-5*/}
          <Box
            marginTop="30px"
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
          >
            <Box>
              <Img src={FractionalizationFeatureImage5} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Transparency & Security
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                All transactions are registered on an immutable digital ledger
                providing investors unparalleled transparency and security for
                their investments.
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default FractionalizationFeature;
