import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  Img,
  Text,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  useBreakpointValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  VStack,
  FormControl,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import headerLogo from '../../assets/images/landing/Group 3835.png';
import { useNavigate } from 'react-router-dom';
import Scroll from '../scroll/scroll';
import Footer from '../footer/index';
import Contact from '../../View/Home/components/Contact';
import { showToast } from '../showToast';
import axios from 'axios';
import ArrowRight from '../../icons/arrow';

const Header = React.memo(
  () => {
    const isXLScreen = useBreakpointValue({ base: false, xl: true });
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    console.log('header');
    const [isScrolled, setIsScrolled] = useState(false);
    const [formData, setFormData] = useState({
      firstname: '',
      lastname: '',
      email: '',
      mobilephone: '',
      message: '',
    });
    const [formErrors, setFormErrors] = useState({});
    const requiredFields = [
      'firstname',
      'lastname',
      'email',
      'mobilephone',
      'message',
    ];
    const { isOpen, onOpen, onClose } = useDisclosure();
    const validateForm = () => {
      const errors = {};
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (formData.email && !emailRegex.test(formData.email)) {
        errors.email = 'Enter a valid email address';
      }

      requiredFields.forEach(fieldName => {
        const formattedFieldName = fieldName.replace('_', ' '); // Replace underscores with spaces
        if (!formData[fieldName].trim()) {
          errors[fieldName] = `${
            formattedFieldName.charAt(0).toUpperCase() +
            formattedFieldName.slice(1)
          } is required`;
        }
      });

      setFormErrors(errors);
      return Object.keys(errors).length === 0; // Return true if there are no errors
    };

    const handleFormChanged = (name, value) => {
      setFormErrors(prevErrors => ({
        ...prevErrors,
        [name]: undefined,
      }));
      setFormData({
        ...formData,
        [name]: value,
      });
    };
    // useEffect(() => {
    //   console.log('form data', formData);
    // }, [formData]);

    const handleSubmitForm = async e => {
      e.preventDefault();
      const isValid = validateForm();
      console.log('trying');
      if (isValid) {
        try {
          // Format the data using the mapping
          const formattedData = {
            fields: Object.keys(formData).map(fieldName => ({
              objectTypeId: '0-1',
              name: fieldName,
              value: formData[fieldName],
            })),
          };
          const response = await axios.post(
            'https://api.hsforms.com/submissions/v3/integration/submit/20164312/0c9151d7-3833-478c-992f-a62b00533bb9',
            JSON.stringify(formattedData),
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (response) {
            setFormData({
              firstname: '',
              lastname: '',
              email: '',
              mobilephone: '',
              message: '',
            });

            console.log(response);
            if (response) {
              showToast(
                'success',
                'Thank you for your interest! Our team will be in contact with you shortly.'
              );
              onClose();
            }
          }
          // console.log('response', response.data);
        } catch (error) {
          console.error('error', error);
          showToast('error', error);

          // this.props.overlayLoader({ status: false, loadingMessage: "" });
        }
      } else {
        showToast('error', 'Please fill in all required fields.');
      }
    };

    const handleDrawerOpen = () => {
      setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
      setIsDrawerOpen(false);
    };
    const navigate = useNavigate();

    const handleHomeClick = () => {
      navigate('/');
      setIsDrawerOpen(false);
    };
    const handleModalClick = () => {
      // navigate('/');
      <Contact />;
      setIsDrawerOpen(false);
    };
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
    };

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    useEffect(() => {
      console.log('isScrolled', isScrolled);
    }, [isScrolled]);

    return (
      <Box
        backgroundColor={isScrolled ? 'white' : 'transparent'}
        pos="sticky"
        top="0"
        width="100%"
        zIndex="1000"
        transition="background-color 0.3s ease" // Add a smooth transition effect
        boxShadow={isScrolled ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none'}
      >
        <Box className={isXLScreen ? 'container' : 'container-responsive'}>
          <Flex
            paddingY="30px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display={{ base: 'none', xl: 'block' }}>
              <HStack gap="35px" marginLeft={{ base: '', lg: '30' }}>
                <Text
                  color="#333"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="normal"
                  _hover={{ color: '#052460' }}
                  cursor="pointer"
                  onClick={handleHomeClick}
                >
                  <Scroll targetId="home" marginTop={110}>
                    Home
                  </Scroll>
                </Text>
                <Text
                  color="#333"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="normal"
                  _hover={{ color: '#052460' }}
                  cursor="pointer"
                  onClick={handleHomeClick}
                >
                  <Scroll targetId="about" marginTop={165}>
                    {' '}
                    About Us
                  </Scroll>
                </Text>
              </HStack>
            </Box>
            <Box>
              <Img src={headerLogo} height="65px" />
            </Box>
            <Box display={{ base: 'block', xl: 'none' }}>
              <IconButton
                icon={<HamburgerIcon />}
                aria-label="Open Menu"
                onClick={handleDrawerOpen}
                // onClick={handleHomeClick}
              />
            </Box>

            <Box display={{ base: 'none', xl: 'block' }}>
              <HStack gap="35px" marginRight={{ base: '', lg: '38' }}>
                <Text
                  color="#333"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="normal"
                  _hover={{ color: '#052460' }}
                  cursor="pointer"
                  onClick={handleHomeClick}
                >
                  <Scroll
                    targetId="plateform"
                    blockBehavior="start"
                    marginTop={250}
                  >
                    {' '}
                    Platforms
                  </Scroll>
                </Text>
                <Text
                  color="#333"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="normal"
                  _hover={{ color: '#052460' }}
                  cursor="pointer"
                  // onClick={handleModalClick}
                  onClick={onOpen}
                >
                  {' '}
                  Contact Us
                  {/* <Scroll targetId="footer12" marginTop={200}> Contact Us </Scroll> */}
                </Text>
              </HStack>
            </Box>
          </Flex>

          {/* Drawer for small screens */}
          <Drawer
            isOpen={isDrawerOpen}
            onClose={handleDrawerClose}
            placement="right"
            trapFocus={false}
          >
            <DrawerOverlay>
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Menu</DrawerHeader>
                <DrawerBody display="flex" flexDirection="column" gap="1%">
                  <Text
                    color="#333"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="normal"
                    _hover={{ color: '#052462', backgroundColor: '#A0AEC0' }}
                    cursor="pointer"
                    padding="5%"
                    onClick={handleHomeClick}
                  >
                    <Scroll targetId="home">Home</Scroll>
                  </Text>
                  <Text
                    color="#333"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="normal"
                    _hover={{ color: '#052462', backgroundColor: '#A0AEC0' }}
                    cursor="pointer"
                    padding="5%"
                    onClick={handleHomeClick}
                  >
                    <Scroll targetId="about"> About Us</Scroll>
                  </Text>
                  <Text
                    color="#333"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="normal"
                    _hover={{ color: '#052462', backgroundColor: '#A0AEC0' }}
                    cursor="pointer"
                    padding="5%"
                    onClick={handleHomeClick}
                  >
                    <Scroll targetId="plateform"> Platforms</Scroll>
                  </Text>
                  <Text
                    color="#333"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="normal"
                    _hover={{ color: '#052462', backgroundColor: '#A0AEC0' }}
                    cursor="pointer"
                    padding="5%"
                    onClick={onOpen}

                    // onClick={handleHomeClick}
                  >
                    Contact Us
                  </Text>
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
        </Box>

        <Modal  size="2xl" margin="20px" isOpen={isOpen} onClose={onClose} isCentered id="contact">
          <ModalOverlay />
          <ModalContent >
            <ModalCloseButton />
            <ModalBody  padding="0px">
              <Box paddingTop="20px" id="contact">
                <Flex alignItems="center" justifyContent="center">
                  <Text
                    width="fit-content"
                    color="#333"
                    fontSize="32px"
                    fontStyle="normal"
                    fontWeight="600"
                    lineHeight="normal"
                  >
                    Let's Talk
                  </Text>
                </Flex>
                <Box
                >
                  <form>
                    <VStack
                      width="100%"
                      padding="20px"
                      gap="20px"
                      alignItems="stretch"
                    >
                      {/* FirstName */}
                      <FormControl
                        isRequired={true}

                        isInvalid={formErrors.firstname}
                      >
                        <Input
                          type="text"
                          onChange={e =>
                            handleFormChanged('firstname', e.target.value)
                          }
                          value={formData.firstname}
                          placeholder="First Name"
                          // Add additional styles as needed
                        />
                      </FormControl>

                      {/* LastName */}
                      <FormControl
                        isRequired={true}

                        isInvalid={formErrors.lastname}
                      >
                        <Input
                          type="text"
                          onChange={e =>
                            handleFormChanged('lastname', e.target.value)
                          }
                          value={formData.lastname}
                          placeholder="Last Name"
                          // Add additional styles as needed
                        />
                      </FormControl>

                      {/* Email */}
                      <FormControl
                        isRequired={true}

                        isInvalid={formErrors.email}
                      >
                        <Input
                          type="email"
                          onChange={e =>
                            handleFormChanged('email', e.target.value)
                          }
                          value={formData.email}
                          placeholder="Email"
                          // Add additional styles as needed
                        />
                      </FormControl>

                      {/* MobilePhone */}
                      <FormControl
                        isRequired={true}

                        isInvalid={formErrors.mobilephone}
                      >
                        <Input
                          type="number"
                          onChange={e =>
                            handleFormChanged('mobilephone', e.target.value)
                          }
                          value={formData.mobilephone}
                          placeholder="Phone Number"
                          // Add additional styles as needed
                        />

                      </FormControl>

                      {/* Message */}
                      <FormControl
                        isRequired={true}

                        isInvalid={formErrors.message}
                      >
                        <Textarea
                          onChange={e =>
                            handleFormChanged('message', e.target.value)
                          }
                          value={formData.message}
                          placeholder="Type Message"
                          rows={4} // Adjust as needed
                          // Add additional styles as needed
                        />

                      </FormControl>
                      <Flex paddingTop="40px" justifyContent="end">
                      <Text
                        width="inherit"
                        borderRadius="5px"
                        fontSize="16px"
                        color="black"
                        fontStyle="normal"
                        fontWeight="500"
                        lineHeight="normal"
                        paddingY="2px"
                        _hover={{
                          color: '#052768',
                          '& svg': {
                            transform: 'translateX(15px)',
                            transition: 'transform 0.5s ease',
                          },
                        }}
                        colorScheme="white"
                        onClick={handleSubmitForm}
                        display="flex"
                        flexDirection="column"
                        gap="10px"
                        cursor="pointer"
                      >
                        Submit
                        <ArrowRight />
                      </Text>
                    </Flex>
                    </VStack>
                  
                  </form>
                </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    );
  },
  () => true
);

export default Header;
