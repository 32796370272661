import { Box, Container, Flex, Img, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import OwnerPortalImage from '../../../../../../assets/images/ai-powered/Rectangle 5843.png';

const OwnerPortal = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleNavigation = () => {
    window.open('https://owners.akru.co/', '_blank');
  };
  return (
    <Container maxW={{ base: '92%', xl: '1050px' }} padding="0px">
      <Box paddingY={{ base: '50px', lg: '120px' }}>
        <>
          <Flex alignItems="center">
            <Box
              display={{ base: 'none', xl: 'block' }}
              paddingTop={{ base: '50px', xl: '0px' }}
              zIndex="1"
              left="-20px"
              position="relative"
              overflow="hidden"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Img
                src={OwnerPortalImage}
                transition="transform 0.3s ease-in-out"
                // opacity={isHovered ? 0.7 : 1}
                transform={`scale(${isHovered ? 1.1 : 1})`}
              />
              {isHovered && (
                <Box
                  width={{ base: '85%', xl: '90%' }}
                  height={{ base: '85%', xl: '90%' }}
                  // border="2px solid #FD8534"
                  pos="absolute"
                  top={{ base: '55%', sm: '53%', xl: '50%' }}
                  left="50%"
                  transform="translate(-50%,-50%)"
                  borderRadius="5px"
                ></Box>
              )}
            </Box>
            <Box
              width={{ base: '100%', xl: '50%' }}
              // width="50%"
            >
              <Text
                width="fit-content"
                color="#333"
                fontSize="32px"
                fontStyle="normal"
                fontWeight=" 600"
                lineHeight="normal"
              >
                Owner Portal
              </Text>
              <Text
                paddingY={{ base: '40px', xl: '20px' }}
                // width="388px"
                width={{ base: '100%', xl: '388px' }}
                color="#333"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="30px"
              >
                AKRU’s Owner Portal streamlines property management, providing
                property owners with powerful tools for efficient oversight,
                including portfolio management, rental income distribution, and
                capital call handling.
              </Text>
              <Box
                zIndex="1"
                left="-20px"
                display={{ base: 'block', xl: 'none' }}
              >
                <Img width="fill-available" src={OwnerPortalImage} />
              </Box>

              <Box
                position="relative"
                // left="-50px"
                paddingTop={{ xl: '50px' }}
                left={{ base: '0px', xl: '-50px' }}
              >
                <Box
                  cursor="pointer"
                  borderRadius="10px"
                  bgGradient="linear(to-r, #18428F, #052768)"
                  _hover={{
                    bgGradient: 'linear(to-r, #052768, #18428F)',
                    '& svg': {
                      transform: 'translateX(15px)',
                      transition: 'transform 0.3s ease',
                    },
                  }}
                  justifyContent="start"
                  paddingX="42px"
                  paddingY="26"
                  onClick={() => {
                    handleNavigation();
                    window.scrollTo(0, 0);
                  }}
                >
                  <Text
                    width="77px"
                    color="#FFF"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="600"
                    lineHeight="20px"
                    textTransform="uppercase"
                    marginLeft="30px"
                  >
                    Learn More
                    <Box paddingTop="5px">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="8"
                        viewBox="0 0 21 8"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.3871 0L21 3.81796L14.3871 7.63592V4.47925H1.16129C0.79607 4.47925 0.5 4.18318 0.5 3.81796C0.5 3.45274 0.79607 3.15667 1.16129 3.15667H14.3871V0Z"
                          fill="white"
                        />
                      </svg>
                    </Box>
                  </Text>
                </Box>
              </Box>
            </Box>
          </Flex>
        </>
      </Box>
    </Container>
  );
};

export default OwnerPortal;
