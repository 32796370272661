import { Box, Container, HStack, Img, Text
  // , VStack
 } from '@chakra-ui/react';
import React from 'react';
import KeyImage1 from '../../../../assets/images/rems/Rectangle 5827.png';
import KeyImage2 from '../../../../assets/images/rems/Rectangle 5828.png';
const Seamless = () => {
  return (
    <Box backgroundColor="#00557F0D">
      <Container maxW={{ base: '92%', xl: '1050px' }} p="0">
        <Box paddingY={{ base: '60px', xl: '100px' }}>
          <HStack
            flexDirection={{ base: 'column', xl: 'row' }}
            alignItems="center"
            gap="20px"
          >
            <Box>
              <Img width="fill-available" src={KeyImage1} />
            </Box>
            <Box>
              <Text
                width={{ base: 'fit-content', xl: '255px' }}
                textAlign="start"
                color="#333"
                fontSize={{ base: '18px', xl: '24px' }}
                fontStyle="normal"
                fontWeight=" 500"
                lineHeight="30px"
              >
                Seamless Agent Collaboration
              </Text>
              <Text
                paddingY={{ base: '20px', xl: '30px' }}
                width={{ base: 'fit-content', xl: '519px' }}
                textAlign="start"
                color="#666"
                fontSize={{ base: '14px', xl: '18px' }}
                fontStyle="normal"
                fontWeight=" 400"
                lineHeight="30px"
              >
                REMS simplifies the integration and collaboration with real
                estate agents, fostering efficient property dealings and
                comprehensive asset management.
              </Text>
              {/* <Box>
                <Box
                  cursor="pointer"
                  paddingX="30px"
                  paddingY="12px"
                  width="180px"
                  backgroundColor="#00557F"
                  borderRadius="5px"
                  color="#FFF"
                  // _hover={{ color: '#00557F', background: '#FFF' }}
                >
                  Request a Demo
                </Box>
              </Box> */}
            </Box>
          </HStack>
          <HStack
            flexDirection={{ base: 'column', xl: 'row' }}
            paddingTop={{ base: '60px', xl: '100px' }}
            alignItems="center"
            gap="20px"
          >
            <Box>
              <Text
                width={{ base: 'fit-content', xl: '292px' }}
                textAlign="start"
                color="#333"
                fontSize={{ base: '18px', xl: '24px' }}
                fontStyle="normal"
                fontWeight=" 500"
                lineHeight="30px"
              >
                Comprehensive Asset Management
              </Text>
              <Text
                paddingY={{ base: '20px', xl: '30px' }}
                width={{ base: 'fit-content', xl: '519px' }}
                textAlign="start"
                color="#666"
                fontSize={{ base: '14px', xl: '18px' }}
                fontStyle="normal"
                fontWeight=" 400"
                lineHeight="30px"
              >
                Developers can categorize assets by type, location, and size.
                With REMS, overseeing everything related to asset management
                becomes intuitive.
              </Text>
              {/* <Box>
                <Box
                  cursor="pointer"
                  paddingX="30px"
                  paddingY="12px"
                  width="180px"
                  backgroundColor="#00557F"
                  borderRadius="5px"
                  color="#FFF"
                  // _hover={{ color: '#00557F', background: '#FFF' }}
                >
                  Request a Demo
                </Box>
              </Box> */}
            </Box>
            <Box>
              <Img width="fill-available" src={KeyImage2} />
            </Box>
          </HStack>
        </Box>
      </Container>
    </Box>
  );
};

export default Seamless;
