import {
  Box,
  Container,
  Flex,
  HStack,
  // Img,
  Text,
  VStack,
  Image,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import RealEstateInvestingImage from '../../../../../../assets/images/fractionalization/Subtract.png';
import Fractionalization from '../../../../../../assets/images/landing/fractionalization.png';
const RealEstateInvesting = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNavigation = () => {
    window.open('https://akru.co/', '_blank');
  };
  return (
    <Container maxW={{ base: '92%', xl: '1050px' }} p="0px">
      <Box>
        <Box position="relative">
          <Box
            paddingTop="50px"
            position="relative"
            // width={{ base: "fit-content", xl: "412px" }}
            // height={{ base: "inherit", xl: "482px" }}
            overflow="hidden"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Image
              src={isMobile ? Fractionalization : RealEstateInvestingImage}
              transition="transform 0.3s ease-in-out"
              // opacity={isHovered ? 0.7 : 1}
              transform={`scale(${isHovered ? 1.1 : 1})`}
            />
            {isHovered && (
              <Box
                // width={{ base: "85%", xl: "90%" }}
                // height={{ base: "85%", xl: "90%" }}
                // border="2px solid #FD8534"
                pos="absolute"
                top={{ base: '55%', sm: '53%', xl: '50%' }}
                left="50%"
                transform="translate(-50%,-50%)"
                borderRadius="5px"
              ></Box>
            )}
          </Box>

          <Box
            paddingRight={{ base: '0', md: '0', lg: '60px' }}
            pos="absolute"
            top={{ base: '106px',md:"200px" ,lg: '324px', xl: '380px' }}
          >
            <Text
              textAlign={{ base: 'center', xl: 'start' }}
              width={{ base: '300px', md: '520px', lg: '520px' }}
              color="#333"
              fontSize={{ base: '30px', xl: '45px' }}
              fontStyle="normal"
              fontWeight="600"
              lineHeight={{ base: '30px', md: '40px', lg: '55px' }}
              marginTop={{ base: '360px', md: '100px', lg: '30px' }}
              marginLeft={{ base: '1rem', md: '0rem' }}
              padding={{ base: '10px' }}
            >
              REAL ESTATE INVESTING
            </Text>
            <Text
              textAlign={{ base: 'center', xl: 'start' }}
              color="#333"
              fontSize={{ base: '16px', md: '28px', lg: '34px' }}
              fontStyle="normal"
              fontWeight="600"
              lineHeight={{ base: '25px', md: '40px', lg: '55px' }}
              marginLeft={{ base: '1rem', md: '0rem' }}
              padding={{ base: '10px' }}
            >
              SECURE, SIMPLE, SWIFT
            </Text>
          </Box>
        </Box>
      </Box>
      <HStack
        gap="50px"
        marginTop={{ base: '40px', xl: '160px' }}
        alignItems={{ base: 'center', md: 'center', lg: 'start' }}
        justifyContent="space-between"
        display={{ base: 'flex', md: 'flex', lg: 'flex' }}
        flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
      >
        <VStack
          gap={{ base: '30px', xl: '70px' }}
          alignItems="start"
          justifyContent={{ base: 'start', xl: 'space-evenly' }}
          w="100%"
        >
          <Text
            width={{ base: 'fit-content', xl: '78%' }}
            color="#333"
            fontSize={{ base: '18', md: '20', lg: '24' }}
            fontStyle="normal"
            fontWeight=" 300"
            lineHeight="normal"
          >
            -------------------- Do you dream of investing in commercial real
            estate but find the high barriers to entry and complicated
            investment process discouraging?
          </Text>
          <HStack
            justifyContent="space-between"
            gap={{ base: '30px', xl: '70px' }}
            alignItems={{ base: 'center', md: 'center', lg: 'start' }}
            display={{ base: 'flex', md: 'flex', lg: 'flex' }}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Text
              width={{ base: '100%', xl: '250px' }}
              color="#666"
              fontSize="16px"
              fontStyle="normal"
              fontWeight=" 400"
              lineHeight="30px"
              marginX={{ base: '20px', lg: '' }}
            >
              AKRU is here to change that. We understand that commercial real
              estate investing is not just for the elite. Our mission is to
              democratize investing by allowing you to invest
              carefully-selected, high-quality assets for as little as $1000.
              Leveraging cutting-edge technology and the
            </Text>
            <Text
              width={{ base: '100%', xl: '250px' }}
              color="#666"
              fontSize="16px"
              fontStyle="normal"
              fontWeight=" 400"
              lineHeight="30px"
              marginX={{ base: '20px', lg: '' }}
            >
              power of security tokenization, we make investing as
              simple-as-a-click through our Fractionalization platform. Join us
              in experiencing a new era in commercial real estate investing.
              <Box
                marginTop={{ base: '30px', xl: '70px' }}
                w="100px"
                height="100px"
                cursor="pointer"
                borderRadius="80px"
                bgGradient="linear(to-r, #18428F, #052768)"
                _hover={{
                  bgGradient: 'linear(to-r, #052768, #18428F)',
                  '& svg': {
                    transform: 'translateX(15px)',
                    transition: 'transform 0.3s ease',
                  },
                }}
                justifyContent="start"
                onClick={() => {
                  handleNavigation();
                  window.scrollTo(0, 0);
                }}
              >
                <Text
                  padding={{ base: '28px', xl: '24px' }}
                  color="#FFF"
                  fontSize={{ base: '12px', xl: '14px' }}
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="20px"
                  textTransform="uppercase"
                >
                  Learn More
                  <Box paddingTop="5px">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="8"
                      viewBox="0 0 21 8"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.3871 0L21 3.81796L14.3871 7.63592V4.47925H1.16129C0.79607 4.47925 0.5 4.18318 0.5 3.81796C0.5 3.45274 0.79607 3.15667 1.16129 3.15667H14.3871V0Z"
                        fill="white"
                      />
                    </svg>
                  </Box>
                </Text>
              </Box>
            </Text>
          </HStack>
        </VStack>
        <VStack width="fit-content" alignItems="start">
          <Flex width="100%" justifyContent="end">
            <Box
              cursor="pointer"
              width="180px"
              height="180px"
              borderRadius="90px"
              border="1px solid #333"
              bgGradient="#FFF"
              _hover={{ bgGradient: 'linear(to-b, #052768, #18428F)' }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                height="inherit"
                color="#333"
                _hover={{ color: '#FFF' }}
              >
                <Text
                  fontSize=" 16px"
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="20px"
                  textTransform="uppercase"
                >
                  Accessible
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Flex width="100%" justifyContent="center">
            <Box
              cursor="pointer"
              width="140px"
              height="140px"
              borderRadius="80px"
              border="1px solid #333"
              bgGradient="linear(to-b, #052768, #18428F)"
              _hover={{ bgGradient: '#FFF' }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                height="inherit"
                color="#FFF"
                _hover={{ color: '#333' }}
              >
                <Text
                  fontSize=" 16px"
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="20px"
                  textTransform="uppercase"
                >
                  INCLUSIVE
                </Text>
              </Flex>
            </Box>
          </Flex>
          <HStack gap="51px">
            <Box
              cursor="pointer"
              width="140px"
              height="140px"
              borderRadius="80px"
              border="1px solid #333"
              bgGradient="#FFF"
              _hover={{ bgGradient: 'linear(to-b, #052768, #18428F)' }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                height="inherit"
                color="#333"
                _hover={{ color: '#fff' }}
              >
                <Text
                  fontSize=" 16px"
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="20px"
                  textTransform="uppercase"
                >
                  SEAMLESS
                </Text>
              </Flex>
            </Box>
            <Box
              cursor="pointer"
              width="140px"
              height="140px"
              borderRadius="80px"
              border="1px solid #333"
              bgGradient="#FFF"
              _hover={{ bgGradient: 'linear(to-b, #052768, #18428F)' }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                height="inherit"
                color="#333"
                _hover={{ color: '#fff' }}
              >
                <Text
                  fontSize=" 16px"
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="20px"
                  textTransform="uppercase"
                >
                  TRANSPARENT
                </Text>
              </Flex>
            </Box>
          </HStack>
          <Box>
            <Box
              cursor="pointer"
              width="80px"
              height="80px"
              borderRadius="80px"
              border="1px solid #333"
              bgGradient="linear(to-b, #052768, #18428F)"
              _hover={{ bgGradient: '#FFF' }}
            ></Box>
          </Box>
        </VStack>
      </HStack>
    </Container>
  );
};

export default RealEstateInvesting;