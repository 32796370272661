import { Box, Container, Flex, Img, Text } from '@chakra-ui/react';
import React from 'react';
import RealEstateFeatureImage1 from '../../../../../../assets/images/Group.png';
import RealEstateFeatureImage2 from '../../../../../../assets/images/Group (1).png';
import RealEstateFeatureImage4 from '../../../../../../assets/images/Group (2).png';
import RealEstateFeatureImage3 from '../../../../../../assets/images/Group 3894.png';

const RealEstateFeature = () => {
  return (
    <Container maxW="1050px" p="0px">
      <Box>
        <Box paddingY="120px">
          <Text
            color="#333"
            textAlign="center"
            fontSize="64px"
            fontStyle=" normal"
            fontWeight="600"
            lineHeight="80px"
          >
            FEATURES
          </Text>
        </Box>
        <Flex justifyContent="space-between" flexWrap="wrap" width="100%">
          {/* card-1 */}
          <Box
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
            marginX={{ base: '15px', lg: '0px' }}
          >
            <Box>
              <Img src={RealEstateFeatureImage1} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Comprehensive Document Analysis
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                Seamlessly convert key documents like asset agreements, security
                contracts, credit documents, and loan agreements into actionable
                data, redefining document analysis for a comprehensive
                understanding of your investments.
              </Text>
            </Box>
          </Box>
          {/* card-2 */}
          <Box
            marginTop={{ base: '30', md: '30', lg: '0' }}
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
            marginX={{ base: '15px', lg: '0px' }}
          >
            <Box>
              <Img src={RealEstateFeatureImage2} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Share Class Identification
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                Identify different investor share classes to provide a clear
                understanding of the distribution of shares and ownership
                structure. This information is crucial for strategic
                decision-making and portfolio optimization.
              </Text>
            </Box>
          </Box>
          {/* card-3 */}
          <Box
            marginTop="30px"
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
            marginX={{ base: '15px', lg: '0px' }}
          >
            <Box>
              <Img src={RealEstateFeatureImage3} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Waterfall Calculations
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                Perform waterfall calculations directly from documents. This
                feature facilitates a quick and accurate assessment of
                distribution priorities and fund flows, streamlining the
                financial management aspect of investments.
              </Text>
            </Box>
          </Box>
          {/* card-4 */}
          <Box
            marginTop="30px"
            width={{ base: '100%', md: '100%', lg: '49%' }}
            bgGradient="linear(to-b, rgba(214, 217, 210, 0.5), rgba(160, 168, 177, 0.5))"
            borderRadius="10px"
            padding="40px"
            marginX={{ base: '15px', lg: '0px' }}
          >
            <Box>
              <Img src={RealEstateFeatureImage4} />
            </Box>
            <Box paddingTop="45px">
              <Text
                color="#333"
                fontSize="24px"
                fontStyle=" normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Direct Document Engagement
              </Text>
              <Text
                paddingTop="29px"
                color="#333"
                fontSize="16px"
                fontStyle=" normal"
                fontWeight="500"
                lineHeight="30px"
              >
                Engage and interact directly with documents, extracting specific
                insights to optimize investment oversight, allowing you to
                obtain targeted information and reducing the time and effort
                required for decision-making processes.
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default RealEstateFeature;
