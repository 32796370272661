import { Box } from '@chakra-ui/react';
import React from 'react';
import UnlockNewFinance from './components/unlockNewFinance';
import CrossChainFeature from './components/crossChainFeature';

const CrossChainData = () => {
  return (
    <Box>
      <UnlockNewFinance />
      <CrossChainFeature />
    </Box>
  );
};

export default CrossChainData;
