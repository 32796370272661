import React from 'react';

const Scroll = ({ targetId, children, blockBehavior = 'center', marginTop = 0 }) => {
  console.log({ targetId, children });
  
  const scrollTo = () => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - marginTop;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  return <div onClick={scrollTo}>{children}</div>;
};

export default Scroll;

